import React, { useEffect, useState } from 'react';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import FaFile from '../Icon/FaFile';
import FaChevronDown from '../Icon/FaChevronDown';
import FaChevronRight from '../Icon/FaChevronRight';
import FaFolder from '../Icon/FaFolder';
import FaFolderOpen from '../Icon/FaFolderOpen';
import styles from './codeTree.module.scss'
import classNames from 'classnames';
import http from '../../http';
import LoadingIcon from '../Icon/LoadingIcon';
import { reMadeTree } from '../../utils/util';
export interface TreeData {
    type: CodeFileType,
    path: string,
    key: string,
    title: string,
    leaf: boolean,
    isOpen: boolean,
    loading: boolean,
    children: TreeData[]
}
export enum CodeFileType {
    'tree' = 'tree',
    'file' = 'file'
}
function CodeTree(props: {
    treeData: TreeData[];
    thread_id: string;
    branch_name: string;
    repo_full_name: string;
    onSelect?: (node: any) => void
    disabled?: boolean
}) {
    const [copyTreeData, setCopyTreeData] = useState<TreeData[]>([])
    const [selectKey, setSelectKey] = useState('')
    useEffect(() => {
        setCopyTreeData(props.treeData)
    }, [props.treeData])

    const renderIcon = (node: any) => {
        if (node.leaf) {
            return <FaFile />;
        }
        return node.isOpen ? <FaFolderOpen /> : <FaFolder />;
    };

    const renderSwitcherIcon = (node: any) => {
        if (node.leaf) {
            return null
        }

        return node.isOpen ? <FaChevronDown /> : <FaChevronRight />;
    };
    const handleClick = (node: any) => {
        if (props.disabled) {
            return;
        }
        // 缓存打开过的path，减少请求和loading
        const loadedPath: string[] = JSON.parse(sessionStorage.getItem('loadedPath') || '[]')
        setSelectKey(node.key)
        if (node.leaf) {
            props?.onSelect && props?.onSelect(node)
        } else {
            if (!node.loading) {
                if (!node.isOpen && !loadedPath.includes(node.path)) {
                    node.loading = true
                    setCopyTreeData([...copyTreeData])
                    http.get(`/api/v1/chat/code-folder?thread_id=${props.thread_id}&file_path=${node.path}&branch_name=${props.branch_name}&repo_full_name=${props.repo_full_name}`).then(
                        (data: any) => {
                            if (data.status) {
                                console.log(data.data.data)
                                const tree = data.data.data
                                const nodeChild = findChild(tree, node.path)
                                node.children = reMadeTree(nodeChild || [])
                                node.isOpen = !node.isOpen
                                node.loading = false
                                setCopyTreeData([...copyTreeData])
                                sessionStorage.setItem('loadedPath', JSON.stringify([...loadedPath, node.path]))
                            }

                        }, (err) => {
                            console.log(err)
                            node.loading = false
                            setCopyTreeData([...copyTreeData])
                        }
                    );
                } else {
                    node.isOpen = !node.isOpen
                    setCopyTreeData([...copyTreeData])
                }
            }

        }

    }
    const findChild = (tree: any[], path: string) => {
        if (tree[0].path === path) {
            return tree[0].children
        } else {
            return findChild(tree[0].children, path)
        }
    }
    return (<div className={styles.codeTree}>
        {copyTreeData.map(node => {
            return (
                <>
                    <div key={node.path} className={classNames(styles.treeNode, node.key === selectKey && node.leaf && styles.treeNode_select)} style={props.disabled ? { cursor: "not-allowed" } : {}} onClick={() => handleClick(node)}>
                        <div className={styles.switcherIcon} style={node.leaf ? { display: 'none' } : {}}>{renderSwitcherIcon(node)}</div>
                        <div className={styles.icon}>{renderIcon(node)}</div>
                        <div className={styles.title} title={node.title}>{node.title}</div>
                    </div>
                    {node.loading && <div className={styles.loading}><LoadingIcon /><span className={styles.loadingText}>loading...</span></div>}
                    {node.children && node.isOpen && <div>
                        <CodeTree
                            thread_id={props.thread_id as string}
                            branch_name={props.branch_name}
                            repo_full_name={props.repo_full_name}
                            disabled={props?.disabled}
                            onSelect={(node) => props?.onSelect && props?.onSelect(node)}
                            treeData={node.children} />
                    </div>}
                </>
            )
        })}
    </div>)
}
export default React.memo(CodeTree)