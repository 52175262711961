import React from 'react'
import styles from './iconStyles.module.scss'

export default function LoadingIcon(props: {
  size?: number
}) {
  return (
    <div style={props.size?{width: props.size,height: props.size}:{}} className={styles.loadingIcon}></div>
  )
}
