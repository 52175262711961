import React from 'react';
import styles from './Modal.module.scss'; // 使用CSS Modules引入样式

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {

    return (
        isOpen ? <div className={styles.wraperOverlay}>
            <div className={styles.wraper}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.title} title={title}>{title}</h2>
                    <button onClick={onClose} className={styles.closeButton}>X</button>
                </div>
                <div className={styles.modalBody}>
                    {children}
                </div>
            </div>
        </div> : null
    );
};

export default Modal;
